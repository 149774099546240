#main {
    min-height: 100vh
}

#login-card {
    width: 80vw
    max-width: 480px 
    & .mdui-card-primary{
        text-align: center
    }
}

#top-container {
    height: 40vh
}

#bottom-container {
    height: 10px
}

#main-card {
    top: -35vh
    padding: 10px
    margin-bottom: 5vh
}

#right-header-btn {
    font-size: 18px 
    line-height: 36px
}

.user-info {
    margin: 15px 0
    text-align: center
    & .user-info-name {
        font-size: 24px
        line-height: 1.25
        font-weight: 700
        opacity: 0.75
        margin-bottom: 5px
    }
    & .user-info-id {
        font-size: 18px
        line-height: 1.5
        font-weight: 600
        opacity: 0.55
    }
}

#announcement-dialog{
    & .mdui-dialog-content, .mdui-btn {
        font-size: 16px 
    }
    & .mdui-dialog-title {
        font-size: 24px 
        line-height: 1.5 
    }
}

.mdui-panel-item-summary{
    width: 12.5%    
    text-align: center
}

.head-font{
    font-weight: 600
    font-size: 17px
}

.panel-description{
    margin-top: 4px
    font-size: 14px
    opacity: 0.75
}

.panel-item{
    font-size: 16px
    font-weight: 700
}

.mdui-panel-item-header{
    height: unset !important
    min-height: 48px
    padding: 8px 0
}

@media only screen and (max-width: 600px) {
    .panel-attach {
        display: none;
    }
    .layui-laydate-content table{
        width: 100%    
    }
    .layui-laydate-range{
        width: 100vw !important
    }
    .layui-laydate-main{
        width: 100% !important
    }
    #right-header-btn {
        display: none    
    }
}

@media only screen and (max-width: 1023px) {
    .panel-rented, .panel-expired {
        display: none;
    }
}

.status-busy{
    color: #FF0000 !important
}

.status-idle{
    color:#008000 !important
}

.status-done{
    color:#A9A9A9 !important
}

.status-undone{
    color: #1E90FF !important
}

#button-row, #input-row{
    margin-top: 10px;
}

#button-row{
    text-align: center
    margin-bottom: 10px
}
